import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Categories from "../components/categories"
import {
  Article,
  Column3,
  ContainerCol2,
  Sidebar,
  Thumbnail,
  Title,
} from "../utils"

export const pageQuery = graphql`
  query ($name: String) {
    allContentfulRecipes(filter: { category: { name: { eq: $name } } }) {
      edges {
        node {
          title
          slug
          category {
            name
            categorySlug
          }
          thumbnail {
            gatsbyImageData
            title
          }
        }
      }
    }
  }
`

const Category = ({ data }) => {
  const recipes = data.allContentfulRecipes.edges
  return (
    <ContainerCol2>
      <Column3>
        {recipes &&
          recipes.map(({ node: recipe }) => {
            return (
              <Article>
                <Link to={`/recipe/${recipe.slug}`}>
                  <Thumbnail>
                    <GatsbyImage image={getImage(recipe.thumbnail)} />
                  </Thumbnail>
                  <Title>{recipe.title}</Title>
                </Link>
              </Article>
            )
          })}
      </Column3>
      <Sidebar>
        <Categories />
      </Sidebar>
    </ContainerCol2>
  )
}

export default Category
